import React, { useState } from 'react';

const GenerateHash = () => {
  const [response, setResponse] = useState(null);

  const generateHash = async () => {
    try {
      const res = await fetch('https://api.1mg.com/api/v6/b2b/generate_hash', {
        method: 'POST',
        headers: {
          'X-Access-key': '1mg_client_access_key',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          number: '6249900260',
          merchant_key: 'ade99fde-bb38-4f5c-95d3-9d857345bd9c', // Provided by 1mg
          user_id: '1',
          source: 'merchant_name', // Provided by 1mg
          redirect_url: 'https://1mg.com',
        }),
      });

      const data = await res.json();
      setResponse(data);
    } catch (error) {
      console.error('Error generating hash:', error);
      setResponse('Error occurred');
    }
  };

  return (
    <div>
   
    </div>
  );
};

export default GenerateHash;
