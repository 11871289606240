import React, { useEffect, useState } from "react";
import axios from "axios";

const MedicineInfo = ({ drugName }) => {
  const [medicineData, setMedicineData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 100;

  useEffect(() => {
    const fetchMedicineData = async () => {
      setLoading(true);

      try {
        const skip = (currentPage - 1) * limit;
        const response = await axios.get(
          `https://api.fda.gov/drug/label.json?search=*&limit=${limit}&skip=${skip}`
        );

        if (response.data.results && response.data.results.length > 0) {
          setMedicineData(response.data.results);
          const totalItems = response.data.meta?.total || 0;
          setTotalPages(totalItems > 0 ? Math.ceil(totalItems / limit) : 0);
        } else {
          setError("No matches found for this medicine.");
        }
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchMedicineData();
  }, [currentPage, drugName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-4">
      <h3 className="mb-4">List of Medicines</h3>
      <div className="row">
        {medicineData.map((medicine, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <p className="card-text">
                  <strong>Brand:</strong>{" "}
                  {medicine.openfda?.brand_name?.[0] || "N/A"} |{" "}
                  <strong>Generic:</strong>{" "}
                  {medicine.openfda?.generic_name || "N/A"} |{" "}
                  <strong>Description:</strong>{" "}
                  {medicine.description || "N/A"} |{" "}
                  <strong>Dosage:</strong>{" "}
                  {medicine.dosage_form || "N/A"} |{" "}
                  <strong>Manufacturer:</strong>{" "}
                  {medicine.openfda?.manufacturer_name || "N/A"} |{" "}
                  <strong>Indications:</strong>{" "}
                  {medicine.indications_and_usage || "N/A"} |{" "}
                  <strong>Warnings:</strong>{" "}
                  {medicine.warnings || "N/A"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-between align-items-center mt-4">
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages || "..."}
        </span>
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
          disabled={currentPage === totalPages || totalPages === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MedicineInfo;
