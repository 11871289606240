import React, { useState } from 'react';
import {
  CitySelect,
  CountrySelect,
  StateSelect
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const CityState = ({ onSelect }) => {
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});

  return (
    <div className='row'>
      <div className='col-md-4'>
        <h6>Country</h6>
        <CountrySelect
          onChange={(country) => {
            setCountry(country);
            onSelect({ country, state, city }); // Pass selected country
          }}
          placeHolder="Select Country"
          region={region}
        />
      </div>
      
      <div className='col-md-4'>
        <h6>State</h6>
        <StateSelect
          countryid={country?.id}
          onChange={(state) => {
            setState(state);
            onSelect({ country, state, city }); // Pass selected state
          }}
          placeHolder="Select State"
        />
      </div>
      
      <div className='col-md-4'>
        <h6>City</h6>
        <CitySelect
          countryid={country?.id}
          stateid={state?.id}
          onChange={(city) => {
            setCity(city);
            onSelect({ country, state, city }); // Pass selected city
          }}
          placeHolder="Select City"
        />
      </div>
    </div>
  );
};

export default CityState;
