import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_PATH } from '../../src/components/env'; // Ensure you are importing correctly


const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_PATH}/post.php?id=${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('API Response:', data);
        if (data.post) {
          setPost(data.post);
        } else {
          throw new Error('Invalid API response structure');
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div className='container'>
      <h1>{post.title}</h1>
      <p>{post.content}</p>
      <p>Author: {post.author}</p>
      <p>City: {post.city}</p>
      <p>Published on: {post.publish_date}</p>
      <p>Likes: {post.numLikes}, Dislikes: {post.numDislikes}</p>
    </div>
  );
};

export default PostDetail;
