import React, { useState } from 'react';
import axios from 'axios';
import CityState from './countrystatecity';

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [location, setLocation] = useState({ country: {}, state: {}, city: {} });
  
  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://your-api-url.com/create-post.php', {
        title,
        content,
        author,
        country: location.country.name,
        state: location.state.name,
        city: location.city.name
      });

      console.log(response.data);
      if (response.data.status === 'success') {
        // Handle success (e.g., navigate, show message)
      } else {
        // Handle error
      }
    } catch (error) {
      console.error('Failed to create post', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Title:</label>
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      </div>
      
      <div>
        <label>Content:</label>
        <textarea value={content} onChange={(e) => setContent(e.target.value)} />
      </div>
      
      <div>
        <label>Author:</label>
        <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} />
      </div>
      
      <CityState onSelect={handleLocationSelect} />
      
      <button type="submit">Create Post</button>
    </form>
  );
};

export default CreatePost;
