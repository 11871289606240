import React, { useState, useEffect } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';

// Example data structure
const initialData = [
  { id: 1, state: "State1", location: "Location1", category: "Category1", title: "Video 1" },
  { id: 2, state: "State1", location: "Location2", category: "Category2", title: "Video 2" },
  { id: 3, state: "State2", location: "Location1", category: "Category1", title: "Video 3" },
  // Add more items as necessary
];

const VideoCategory = () => {
  const [stateFilter, setStateFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [filteredData, setFilteredData] = useState(initialData);

  // Function to handle data filtering
  const filterData = () => {
    let data = initialData;

    if (stateFilter) {
      data = data.filter(item => item.state === stateFilter);
    }

    if (locationFilter) {
      data = data.filter(item => item.location === locationFilter);
    }

    if (categoryFilter) {
      data = data.filter(item => item.category === categoryFilter);
    }

    setFilteredData(data);
  };

  // Run filter function whenever any filter value changes
  useEffect(() => {
    filterData();
  }, [stateFilter, locationFilter, categoryFilter]);

  // Placeholder image URL
  const img = "https://static.pexels.com/photos/7096/people-woman-coffee-meeting.jpg";

  return (
    <div className='row container'>
      {/* Dropdowns for filters */}
      <div className='col-md-4'>
      <select onChange={(e) => setStateFilter(e.target.value)} className="form-select my-2">
        <option value="">Select State</option>
        <option value="State1">State1</option>
        <option value="State2">State2</option>
      </select>
      </div>
      <div className='col-md-4'>
      <select onChange={(e) => setLocationFilter(e.target.value)} className="form-select my-2">
        <option value="">Select Location</option>
        {stateFilter === "State1" && <option value="Location1">Location1</option>}
        {stateFilter === "State1" && <option value="Location2">Location2</option>}
        {stateFilter === "State2" && <option value="Location1">Location1</option>}
      </select>
</div>    <div className='col-md-4'>
      <select onChange={(e) => setCategoryFilter(e.target.value)} className="form-select my-2">
        <option value="">Select Category</option>
        <option value="Category1">Category1</option>
        <option value="Category2">Category2</option>
      </select>
      </div>

      {/* Display filtered VideoCategory cards */}
      <div className="video-cards row">
        {filteredData.map((item) => (














          <div key={item.id} className="video-card col-md-4 mb-4">
            <div className="card" style={{ width: "18rem" }}>
              <img src={img} className="card-img-top" alt={item.title} />
              <div className="card-body">
                <h5 className="card-title">{item.title}</h5>
                <p className="card-text">
                  {item.state} - {item.location} - {item.category}
                </p>
                <a href="#" className="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoCategory;
