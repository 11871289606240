import React from 'react';
import MedicineDetails from '../components/MedicineDetails';

function App() {
  const drugSkuId = '12345'; // Replace with actual drug SKU ID

  return (
    <div className="App">
      <MedicineDetails drugSkuId={drugSkuId} />
    </div>
  );
}

export default App;
