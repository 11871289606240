import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { ROOT_URL } from '../../src/components/env';
import CityState from './countrystatecity';

export const API_PATH = `${ROOT_URL}/server/api`;

const PostList = () => {



    

  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cities, setCities] = useState([]); // List of available cities for the dropdown
  const [selectedCity, setSelectedCity] = useState(''); // Selected city for filtering

  const navigate = useNavigate();




  // Fetch cities for the dropdown
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(`${API_PATH}/cities.php`);
        setCities(response.data.cities || []);
      } catch (error) {
        console.error('Failed to fetch cities:', error);
      }
    };
    fetchCities();
  }, []);



  // Fetch posts based on the selected city
  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_PATH}/posts.php`, {
          params: { page: 1, city: selectedCity },
        });
        const data = response.data;
        if (data && Array.isArray(data.posts)) {
          setPosts(data.posts);
        } else {
          throw new Error('Invalid API response structure');
        }
      } catch (error) {
        console.error('There was a problem with the axios operation:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPosts();
  }, [selectedCity]); // Refetch posts whenever selectedCity changes

  const handleVote = async (postId, voteType) => {
    try {
      await axios.post(`${API_PATH}/vote-post.php`, {
        post_id: postId,
        vote_type: voteType,
      });
    } catch (error) {
      console.error('Failed to cast vote:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  

  return (
    <div className='container'>
  
      <CityState />
      <h1>Post List test</h1>

     

      {posts.length > 0 ? (
        posts.map((post) => (
          <Card className='postcard' key={post.id}>
            <Card.Body>
              <Card.Title>{post.title}</Card.Title>
              <Card.Text className='two-line-title'>{post.content}</Card.Text>
              <p>Author: {post.author}</p>
              <p>City: {post.city}</p>
              <p>Published on: {post.publish_date}</p>
              <p>Likes: {post.numLikes}, Dislikes: {post.numDislikes}</p>
              <Button variant="primary" onClick={() => navigate(`/post/${post.id}`)}>View detail</Button>
              <Button variant="success" onClick={() => handleVote(post.id, 'like')}>Like</Button>
              <Button variant="danger" onClick={() => handleVote(post.id, 'dislike')}>Dislike</Button>
            </Card.Body>
          </Card>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default PostList;
